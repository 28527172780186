export const GET_HEADER_NAV = 'get_header_nav';
export const CLEAR_HEADER_NAV = 'clear_header_nav';
export const LOGIN_USER = 'login_user';
export const UPDATE_PASSWORD = 'update_password';
export const CLEAR_PASSWORD = 'clear_password';
export const GET_ROLES = 'get_roles';
export const USER_AUTH = 'user_auth';
export const GET_USER = 'get_user';
export const CLEAR_GET_USER = 'clear_get_user';
export const GET_USERS = 'get_users';
export const GET_WALLET_TRANSACTIONS = 'get_wallet_transactions';
export const GET_TRANSACTIONS = 'get_transactions';
export const GET_USER_WALLET = 'get_user_wallet';
export const CONFIRM_TRANSACTION = 'confirm_transactions';
export const ARCHIVE_TRANSACTION = 'archive_transactions';
export const GET_CURRENCIES = 'get_currencies';
export const GET_PAYMENTS = 'get_payments';
export const CREDIT_WALLET = 'credit_wallet';
export const CLEAR_TRANSACTION = 'clear_transaction';
export const USER_REGISTER_USER = 'user_register_user';
export const USER_UPDATE_USER = 'user_update_user';
export const CLEAR_REGISTER_AND_UPDATE = 'clear_register_and_update';
export const USER_ENABLE = 'user_enable'
export const USER_DISABLE = 'user_disable';
export const USER_ARCHIVE = 'user_archive';
export const GET_CODES = 'get_codes';
export const GENERATE_CODE = 'generate_code';
export const DELETE_CODE = 'delete_code';
export const CLEAR_GENERATE_CODE = 'clear_generate_code';
export const CHECK_CODE ='check_code';
export const CLEAR_CHECK_CODE = 'clear_check_code';
export const SAVE_TRANSFER = 'save_transfer';
export const CLEAR_TRANSFER = 'clear_transfer';
export const GET_NOTIFICATIONS = 'get_notifications';
export const NOTIFICATION_ENABLE = 'notification_enable';
export const NOTIFICATION_DISABLE = 'notification_disable';
export const USER_REGISTER_NOTIFICATION = 'user_register_notification';
export const USER_UPDATE_NOTIFICATION = 'user_update_notification';
export const CLEAR_REGISTER_UPDATE_NOTIFICATION = 'clear_register_update_notification';
export const GET_USER_NOTIFICATION = 'get_user_notifications';