import {faHome, faFileText, faSignIn, faArrowRight, faSignOut } from '@fortawesome/free-solid-svg-icons';
import {faUser } from '@fortawesome/free-solid-svg-icons';

export const HeaderLinkItems = [
    {
        type: 'navItem',
        icon: faHome,
        text: 'About Banco',
        link: '/aboutUs',
        position:'menu-1',
        restricted: false,
        role:'all'
    },
    
    {
        type: 'navItem',
        icon: faFileText,
        text: 'Careers',
        link: '/career',
        position:'menu-1',
        restricted: false,
        role:'all'
    },

    {
        type: 'navItem',
        icon: faHome,
        text: 'Open an account',
        link: '/user/register',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },

    {
        type: 'navItem',
        icon: faHome,
        text: 'Pay / Send money',
        link: '/user/wallet/transfer',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },

    {
        type: 'navItem',
        icon: faHome,
        text: 'Borrow',
        link: '/borrow',
        position:'menu-1',
        restricted: false,
        role:'all'
    },


    {
        type: 'navItem',
        icon: faUser,
        text: 'Dashboard',
        link: '/user',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'all'
    },
    {
        type: 'navItem',
        icon: faHome,
        text: 'Users',
        link: '/user/list',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },
    {
        type: 'navItem',
        icon: faHome,
        text: 'Notifications',
        link: '/user/notifications',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },

    {
        type: 'navItem',
        icon: faHome,
        text: 'Transfer Code',
        link: '/user/wallet/codes',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },
    {
        type: 'navItem',
        icon: faHome,
        text: 'Transactions',
        link: '/user/admin/transactions',
        position:'menu-2',
        restricted: true,
        showAdmin:true,
        exclude: true,
        role:'admin'
    },
    
    {
        type: 'navItem',
        icon: faSignIn,
        text: 'SignIn',
        position:'menu-1',
        link: '/login',
        restricted: false,
        exclude: true,
        role:'all'
    },
    {
        type: 'navItem',
        icon: faSignOut,
        text: 'Logout',
        position:'menu-1',
        link: '/user/logout',
        restricted: true,
        showAdmin:true,
        role:'all'
    }
];