import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { clearHideHeader, getHideHeader } from '../actions';
import Notification from './Admin/notification';
import { Link } from 'react-router-dom';

const NotificationsContainer = (props) => {

    useEffect(() => {
        props.dispatch(getHideHeader(true));
         return () => {
            props.dispatch(clearHideHeader());
         }
     }, []);

        return (
            <div className='admin_container'>
                {/*<div> Search</div>*/}
            
                <div className='container-header'>
                <div className='container-title'>
                    <h1>  Notifications Panel</h1>
                </div>
               
                <div className='btn-generate' >
                    <Link to="/register/notification"> register</Link>
                </div>
               </div>
               <Notification showMore={true} {...props} />
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(NotificationsContainer);