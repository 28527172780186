import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import Codes from './Admin/codes';
import { clearHideHeader, getHideHeader } from '../actions';
import { Link } from 'react-router-dom';


const CodeContainer = (props) => {

    useEffect(() => {
        props.dispatch(getHideHeader(true));
         return () => {
            props.dispatch(clearHideHeader());
         }
     }, []);

        return (
            <div className='admin_container'>
              {/* <div> Search</div> */}

              <div className='container-header'>
                <div className='container-title'>
                    <h1>  Manage Transfer code</h1>
                </div>
               
                <div className='btn-generate' >
                    <Link to="/user/wallet/generateCode"> Generate</Link>
                </div>
            </div>
               <Codes showMore={true} {...props} />
        
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(CodeContainer);