import React from 'react';
import HomeContainer from '../../containers/home_container';

const Home = () => {
    return (
        <div>
            <HomeContainer/>
        </div>
    );
};

export default Home;