import React from 'react';

const Search = () => {
    return (
        <div className="search-container">
            
        </div>
    );
};

export default Search;