import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const Partner = () => {
    return (
        <div className='partner-container'>

            <div className='partner-message'>
                <h2>
                BANCO CONNECT INTERNATIONAL GROUP FOUNDATION PARTNERS WITH OTHERS FOUNDATIONS WORLD WIDE.
                </h2>
                <h3> Banco Connect Leader In The Offsore Service Provider (<strong>OSP</strong>).
                </h3>
                <p>
                Here, you can value your business and assets. 
                </p>
                <p>
                Account here are only set for VIP customers.
                </p>
            </div>


            {<div className='learn-more-btn'>
                <Link to="/partnerMore">
                    Learn More
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </Link>
             </div> }
        </div>
    );
};

export default Partner;